<template>
  <select :id="id" v-model="value"
          style="border-radius: 2px;"
          :class="{search: allowAdditions}" 
          class="ui dropdown">
    <slot></slot>
  </select>
</template>

<script>
export default {
  name: 'dropdown',
  props: {
    v: {default: () => []},
    allowAdditions: {default: false}
  },
  data () {
    return {
      id: Math.random().toString(36).substr(2, 9),
      value: this.v
    }
  },
  watch: {
    value: function (v) {
      this.$emit('update:value', v)
      this.$emit('change', v)
    }
  },
  mounted () {
    // eslint-disable-next-line no-undef
    $(`#${this.id}`).dropdown({
      'set selected': this.v,
      allowAdditions: this.allowAdditions,
      forceSelection: false,
      hideAdditions: false
    })
  }
}
</script>

<style lang="css">
  .ui.label {
    box-shadow: none !important;
    border-radius: 2px !important;
    font-size: 11px !important;
  }
  .ui.dropdown {
    border: 2px;
  }
  .dropdown .text, .dropdown .item {
    font-size: 12px !important;
  }
  .dropdown.active {
    border-color: #5ccbaa !important;
  }
  /* .dropdown {
    min-height: 12px !important;
    padding: 10px 14px !important;
  } */
  .dropdown.icon {
    top: 11px !important;
  }
</style>
