<template>
  <div class="progressbar wfill" :style="{
      height: height + 'px'
    }">
    <div 
      v-if="percentOf !== 0" 
      :style="{
        width: percentOf + '%',
        height: height + 'px'
      }"
      class="brown500 h10">
    </div>
  </div>
</template>

<script>
export default {
  name: 'progressbar',
  props: {
    height: {default: 10},
    progress: {default: 0},
    outOf: {default: 100},
  },
  computed: {
    percentOf: function () {
      return this.progress * 100 / this.outOf
    }
  }
}
</script>

<style>
  .progressbar {
    background: #f5f5f5;
  }
</style>